<template>
  <div>
    <video class="embed-responsive-item video" ref="video" controls autoplay muted loop preload="auto" playsinline type="video/mp4">
      <source :src="require(`@/assets/files/IntroVideo.mp4`)">
    </video>
  </div>
</template>

<script>
export default {

}
</script>

<style>
video {
  height: 100vh;
  width: 100%;
}
</style>